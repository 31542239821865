.wrapper {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    padding: 20px;
    border-radius: 12px;
}

.titleBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.title {
    font-size: 24px;
    font-weight: 700;
}

.close {
    font-weight: 700;
    font-size: 14px;
    color: gray;
    cursor: pointer;
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
}

.close:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.walletsWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.wallet {
    display: flex;
    align-items: center;
    gap: 20px;
    border: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.wallet:hover {
    background-color: rgba(0, 0, 0, 0.1);
}