@import url('https://rsms.me/inter/inter.css');
.app-container {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.body-main {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 20%;
}

.body-content {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.body-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.body-sidebar {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 15%;
}

.coming-soon-container {
  align-self: center;
  border-radius: 1em;
  background-color: rgb(40, 40, 40);
  border-style: solid;
  border-width: 2px;
  border-color: #384558;
  color: lightgray;
  display: flex;
  flex-direction: column;
  font-size: 2em;
  padding: 2em;
  text-align: center;
  width: 40%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 20% 20px;
}

.logo {
  max-width: 100%;
  width: auto;
}

.nav-tab {
  border-bottom-style: solid;
  border-bottom-color: rgb(123, 123, 123);
  margin-right: 1em;
  margin-left: 1em;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.nav-tab-active {
  border-bottom-style: solid;
  border-bottom-color: rgb(208, 208, 208);
  margin-right: 1em;
  border-radius: 3px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.nav-tab:hover {
  border-color: #918e9a;
  color: rgb(231, 231, 231);
}

.nav-tab-container {
  display: flex;
  flex-direction: row;
  color: rgb(167, 167, 167);
  padding-bottom: 30px;
  padding-top: 20px;
  justify-content: center;
}

.tokenstatcontainer {
  align-items: flex-start;
  background-color: rgb(30, 30, 30);
  border-radius: 5px;
  border-color: black;
  border: rgb(74, 65, 60) solid 2px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  padding: 10px;
  width: 100%;
}

.tokenstatlabel {
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  color: white;
  font-size: 0.9em;
  padding: 5px;
  text-align: center;
  width: 100%;
}

.tokenstatAmount {
  font-size: 1.2em;
  font-weight: 700;
  text-align: center;
  width: 100%;
  color: #878ca3;
  padding: 0.25em;
  font-feature-settings: 'ss01', 'ss02', 'cv01', 'cv03';
  font-family: Inter Var;
}

.coingeckocontainer {
  color: white;
  display: flex;
  font-size: 1.2em;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.poolstats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5em;
  width: 100%;
}

.poolstatsitem {
  border-radius: 1em;
  background-color: rgb(40, 40, 40);
  border-style: solid;
  border-width: 2px;
  border-color: #384558;
  display: flex;
  flex-direction: column;
  font-feature-settings: 'ss01', 'ss02', 'cv01', 'cv03';
  font-family: Inter Var;
  width: 30%;
  padding: 10px;
}

.poolstatsitemlabel {
  background-color: rgb(44, 44, 44);
  border-radius: 1em;
  color: white;
  font-size: 0.9em;
  padding: 5px;
  text-align: center;
  width: 100%;
}

.poolstatsitemvalue {
  display: flex;
  font-size: 1.4em;
  width: 100%;
  color: lightgray;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.mystats {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 30vh;
  margin-top: 1em;
}

.mystatsstake {
  background-color: rgb(40, 40, 40);
  border-width: 2px;
  border-color: #384558;
  border-style: solid;
  border-radius: 1em;
  display: flex;
  flex-direction: row;
  height: 50%;
  margin-bottom: 1.5em;
}

.mystatsearnings {
  background-color: rgb(40, 40, 40);
  border-color: #384558;
  border-radius: 1em;
  border-style: solid;
  border-width: 2px;
  flex-direction: row;
  display: flex;
  margin-bottom: 1.5em;
}

.mystatstakelabel {
  color: white;
  font-size: 1.3em;
  margin-bottom: 1em;
  text-align: center;
}

.mystatstakeamount {
  color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: Inter Var;
  font-feature-settings: 'ss01', 'ss02', 'cv01', 'cv03';
  font-size: 2em;
  font-weight: 700;
  justify-content: center;
  text-align: center;
}

.mystatsitem {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 50%;
}

.userstats {
  background-color: rgb(40, 40, 40);
  border-color: #384558;
  border-radius: 1em;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-direction: row;
  height: 75px;
  margin-bottom: 1.5em;
  width: 100%;
}

.userstatsitem {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 10px;
  width: 33%;
}

.userstatsitemlabel {
  font-size: 0.9em;
  width: 100%;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.userstatsitemvalue {
  display: flex;
  font-size: 1.2em;
  width: 100%;
  color: lightgray;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-feature-settings: 'ss01', 'ss02', 'cv01', 'cv03';
  font-family: Inter Var;
}

.myearningsitem {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 33%;
}

.myearningslabel {
  color: white;
  font-size: 1.3em;
  text-align: center;
  margin-bottom: 1em;
}

.myearningslabelsmall {
  color: white;
  font-size: 1em;
  text-align: center;
  margin-bottom: 1em;
}

.myearningsamount {
  color: rgb(212, 200, 166);
  font-size: 1.2em;
  font-weight: 700;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-feature-settings: 'ss01', 'ss02', 'cv01', 'cv03';
  font-family: 'Inter var';
  text-align: center;
}

.buttonbar {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonbarbutton {
  background: linear-gradient(to right, #f18a00, #eba900) padding-box,
    /*this is your grey background*/
      linear-gradient(to bottom, #f18a00, #eba900) border-box;
  border-radius: 10px;
  border-style: none;
  color: white;
  font-family: 'Inter Var';
  font-weight: 500;
  min-width: 30%;
  padding: 1em;
}
.testbuttons {
  display: flex;
}
.testbuttons > * {
  margin-right: 2px;
}
@media (min-width: 768px) {
  .buttonbarbutton.change {
    margin-left: 5px;
  }
}

@media (max-width: 768px) {
  .header-inner,
  .buttongroup,
  .testbuttons,
  .button-row {
    flex-direction: column;
  }
  .testbuttons > * {
    margin-bottom: 5px;
  }
}
.buttonbarbalance {
  background: #666666;
  border-radius: 10px;
  border-style: none;
  color: white;
  font-family: 'Inter Var';
  font-weight: 500;
  min-width: 40%;
  display: flex;
  justify-content: space-between;
}

.button-row {
  display: flex;
}

.button-row > *:not(:first-child) {
  margin-left: 10px;
}

.buttonbarbalancevalue {
  background: #666666;
  border-radius: 10px;
  border-style: none;
  color: white;
  font-family: 'Inter Var';
  font-weight: 500;
  min-width: 40%;
  padding: 1em;
}

.buttonbarbutton:hover {
  background: linear-gradient(to right, #f18a00, #eba900) padding-box,
    /*this is your grey background*/
      linear-gradient(to bottom, #f18a00, #eba900) border-box;
  border-radius: 10px;
  border-style: none;
  color: white;
  font-family: 'Inter Var';
  font-weight: 500;
}

.buttonbarbutton:active {
  background: linear-gradient(to right, #f18a00, #eba900) padding-box,
    /*this is your grey background*/
      linear-gradient(to bottom, #f18a00, #eba900) border-box;
  color: white;
  font-family: 'Inter Var';
  border-radius: 10px;
  border-style: none;
  font-weight: 500;
  transform: translate(5px, 5px);
}

.buttonbarbutton:focus {
  outline: none;
}

.buttonbarrow {
  display: flex;
}

.buttonbar {
  width: 100%;
}

@media (min-width: 600px) {
  .buttonbarmobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .body {
    justify-content: center;
  }

  .body-main {
    width: 100vw;
    padding: 0 10px;
    align-items: center;
  }

  .body-sidebar {
    display: none;
  }

  .buttonbarbalance {
    margin-bottom: 1em;
  }

  .buttonbar {
    display: flex;
    flex-direction: column;
  }

  .buttonbar > *:not(:first-child) {
    margin-top: 1em;
  }

  .buttonbarbutton {
    box-shadow: inset 0 0 3px 3px rgb(255, 156, 8);
  }

  .buttonbarmobile {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    align-items: center;
    flex-direction: column;
  }

  .logo {
    margin-bottom: 30px;
  }

  .myearningsamount {
    margin: 0;
    flex-grow: 0;
    font-size: 0.8em;
    margin: 0;
  }

  .myearningsitem {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    width: 100%;
  }

  .myearningslabel {
    font-size: 1em;
    margin: 0;
  }

  .myearningslabelsmall {
    text-align: left;
  }

  .mystatsearnings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
  }

  .mystatstakeamount {
    font-size: 0.7em;
  }

  .mystatstakelabel {
    font-size: 0.8em;
  }

  .poolstats {
    flex-direction: column;
    align-items: center;
  }

  .poolstatsitem {
    margin-top: 1em;
    width: 100%;
  }

  .poolstatsitemlabel {
    font-size: 0.7em;
  }

  .poolstatsitemvalue {
    font-size: 0.8em;
  }

  .nav-container {
    display: none;
  }

  .userstats {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .userstatsitem {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
    margin-left: 0.5em;
    padding-right: 1em;
  }

  .userstatsitemlabel {
    text-align: left;
  }

  .userstatsitemvalue {
    font-size: 0.8em;
  }
}

.modal {
  display: none;
  background-color: rgba(56, 56, 56, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modalbalance {
  color: rgb(195, 197, 203);
  text-align: right;
  font-size: 0.9em;
  font-feature-settings: 'ss01', 'ss02', 'cv01', 'cv03';
}
.modalcontent-shown {
  padding: 2em;
  background-color: rgb(33, 36, 41);
  border-radius: 15px;
  width: min-content;
  min-width: 40vw;
  height: min-content;
  flex-direction: column;
}
.modalcontent {
  display: none;
  padding: 2em;
  background-color: rgb(33, 36, 41);
  border-radius: 15px;
  width: min-content;
  min-width: 30vw;
  height: min-content;
  flex-direction: column;
}

.modalbuttonbar {
  display: flex;
  flex-direction: row;
}

.modaltitle {
  color: rgb(209, 209, 209);
  font-size: 1.4em;
  width: 100%;
  /* border-bottom-left-radius: 2px; */
  padding-bottom: 1em;
}

.modalinput {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.modalinputamount {
  background-color: rgb(33, 36, 41);
  border-radius: 10px;
  padding: 0.5em;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
  outline: none;
  border: solid #494949 1px;
  margin-top: 1em;
  font-size: 1.3em;
  color: white;
}

.modalinputmax {
  background: linear-gradient(to right, #f18a00, #eba900) padding-box,
    /*this is your grey background*/
      linear-gradient(to bottom, #f18a00, #eba900) border-box;
  border-radius: 5px;
  outline: none;
  border: solid #494949 1px;
  margin-top: 1.4em;
  font-size: 0.8em;
  color: white;
  margin-left: -4em;
}

.modalinputlabel {
  margin-left: 0.75em;
  margin-top: 1em;
  font-size: 20px;
  color: white;
  color: rgb(208 208 208);
  font-weight: 500;
}

.modalbutton {
  margin: 1em;
  border-radius: 10px;
  padding: 0.5em;
  font-size: 1.1em;
  background: linear-gradient(to right, #f18a00, #eba900) padding-box,
    /*this is your grey background*/
      linear-gradient(to bottom, #f18a00, #eba900) border-box;
  border: none;
  color: white;
  outline: none;
  flex-grow: 1;
}
.modalerror {
  color: #ff0000;
  padding: 5px;
  margin-top: 5px;
  display: block;
}

.modalbutton-error {
  margin: 1em;
  border-radius: 10px;
  padding: 0.5em;
  font-size: 1.1em;
  background: linear-gradient(to right, #f10000, #eb0000) padding-box,
    /*this is your grey background*/
      linear-gradient(to bottom, #f10000, #eb0000) border-box;
  border: none;
  color: white;
  outline: none;
  flex-grow: 1;
}
.modalbutton-error:hover {
  cursor: not-allowed;
}

.connectbuttonFalse {
  margin: 1em;
  margin-left: 55em;
  border-radius: 10px;
  padding: 0.5em;
  font-size: 1.1em;
  background-color: rgb(25, 102, 212);
  border: none;
  color: white;
  outline: none;
  align-self: end;
}

.connectbuttonTrue {
  margin: 1em;
  margin-left: 55em;
  border-radius: 10px;
  padding: 0.5em;
  font-size: 1.1em;
  background-color: rgb(0, 255, 0);
  border: none;
  color: white;
  outline: none;
  align-self: end;
}

.modalbuttoninactive {
  margin: 1em;
  border-radius: 10px;
  padding: 0.5em;
  font-size: 1.1em;
  background-color: rgb(108, 114, 132);
  border: none;
  color: white;
  outline: none;
  flex-grow: 1;
}

.modalbutton:active {
  transform: translate(5px, 5px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.nominwidth {
  min-width: 0 !important;
}
.nominwidth.red {
  background: none;
  background-color: red !important;
  margin-left: 5px;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

button {
  cursor: pointer;
}

input[type='button'] {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: rgb(123, 123, 123);
}

a:visited {
  color: rgb(123, 123, 123);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
.addressInput {
  background: #666;
  color: white;
  box-shadow: none;
  border: none;
  border-radius: 10px;
  font-family: 'Inter Var';
  padding: 1em;
  font-weight: 500;
  margin-right: 5px;
}

.addressInput:focus,
.addressInput:focus-visible {
  outline: none !important;
  border: none;
}

:root {

  /* SPACING */
  --onboard-spacing-1: 3rem;
  --onboard-spacing-2: 2rem;
  --onboard-spacing-3: 1.5rem;
  --onboard-spacing-4: 1rem;
  --onboard-spacing-5: 0.5rem;

  /* SHADOWS */
  --onboard-shadow-1: 0px 4px 12px rgba(0, 0, 0, 0.1);
  --onboard-shadow-2: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  
  --onboard-modal-z-index: 10
}